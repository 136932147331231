<template>
  <div class="hello">
      <h1>Welcome to Kind Top family</h1>
    <h2>Under re-contruction; New page will come soon</h2>
	<h2>------------ Smart Home Control -------------</h2>  
  
	  
  
    
  </div>
</template>

<script>

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>



hello.h1 {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 24px;
  text-align: center;
  color: white;
}

hello.h2 {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 14px;
  text-align: center;
  text-color: white;
}


</style>
