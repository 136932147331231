<template>
   <div class="container">
  
     
      <div style="color: white; font-size: 40px;">Welcome to Kind Top family</div>
      <br> 
      <div style="color: white; font-size: 30px;">Smart Home Control</div>
      <br>
      <div style="color: white; font-size: 20px;">Under re-contruction</div>
      <br>
       <div style="color: white; font-size: 20px;">New page will come soon</div>
      <br>
     
      <br>
      <br><br>
      <br>
      <br>
       <br>
      <br>
      <br><br>
      <br>
      <br>
      <br>
      <br>
      <br><br>
      <br>
      <br>
       <br>
      <br>
      <br><br>
      <br>
      <br>

  
	  
  
    
  </div>
  
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>



#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
  

  padding-top: 60px;
  padding-left: 20px;
  padding-right: 20px;

  
  background-size:cover;
  background-image: url('./assets/background.jpg');
 
}

.h1 {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 24px;
  text-align: center;
  text-color: white;
}

.h2 {
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 14px;
  text-align: center;
  text-color: white;
}

</style>
